import React, { Component } from "react"
import Countdown from "./Countdown"
import { Navbar, Container, Col } from "react-bootstrap"

class App extends Component {
  render() {
    return (
      <Navbar expand="lg" variant="light" fixed="top" className="purple">
        <Container fluid>
          <Col lg={6} xs={12}>
            <div className="text-App">
              <p>PENAWARAN AKAN BERAKHIR PADA : </p>
            </div>
          </Col>
          <Col lg={6} xs={12}>
            <div className="App">
              <Countdown date={`January 31, 2023 22:00:00`} />
            </div>
          </Col>
        </Container>
      </Navbar>
    )
  }
}

export default App
